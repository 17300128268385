<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row no padding margin">
          <div class="col-md-3 col-12">
            <h1 class="sub heading">{{ getCourseName(type) }}</h1>
          </div>
          <div class="col-md-9 col-12">
            Besuche einen unserer {{ getCourseName(type) }}-Kurse.

          </div>
        </div>
        <div v-if="type === 'moto'" class="alert alert-info">
          Seit dem 1. Januar 2021 muss jede Fahrschülerin und jeder Fahrschüler alle 3 Motorradkurse (Kurs 1, Kurs 2
          und Kurs 3) besuchen. Dies ist unabhängig von der Kategorie und des Motorrads, welches die Fahrschülerin
          oder der Fahrschüler fährt.
        </div>
      </div>
    </div>

    <course-list-component :courses="courses" v-bind:number="number"></course-list-component>
  </div>
</template>

<script>
import CourseService from '@/services/CourseService';
import CourseListComponent from '@/views/Components/CourseListComponent';

export default {
  name: 'CourseType',
  components: {CourseListComponent},
  props: {
    type: {
      type: String,
      required: true
    },
    number: {
      type: String,
      default: '0',
      required: false
    }
  },
  data() {
    return {
      courses: null,
    };
  },
  mounted() {
    this.loadCourses();
  },
  methods: {
    loadCourses() {
      const map = {
        'vku-intensivkurs': 10
      };
      let searchType = this.type;

      if (map[this.type]) {
        searchType = map[this.type];
      }

      CourseService
          .getCourseList(searchType)
          .then(response => this.courses = response.data);
    },
    getCourseName: function (filter) {
      if (filter.indexOf('vku') !== false) {
        return 'VKU';
      }

      if (filter === 'car') {
        return 'Auto';
      }

      if (filter === 'moto') {
        return 'Motorrad';
      }

      if (filter === 'anh') {
        return 'Anhänger';
      }

      if (filter === 'spezial') {
        return 'Spezial';
      }

      if (filter === 'fahrbegleiter') {
        return 'Fahrbegleiter';
      }

      if (filter === 'senior') {
        return 'Seniorenkurs';
      }

      return 'Kurse';
    }
  }
};
</script>
